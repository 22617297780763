.search_box {
    border: 1px solid purple;
    width: 90%;
}

.search_result {
    margin: 10px;
    justify-content: start;
    display: flex;
    line-height: 50px;
    vertical-align: middle;
}

.search_result_thumbnail {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.search_results_kind {
    margin-right: 10px;
}

.search_results_username{
    font-weight: bold;
    margin-right: 10px;
}

.search_results_verified {
    position: relative;
    top: 8px;
    margin-right: 10px;
}

.discover {
    display: grid;
    grid-template-columns: 2fr 10fr;
}

@media only screen and (max-width: 600px){
    .discover {
        grid-template-columns: 1fr;
    }
}