.drop {
    background: purple;
    height: 100px;
    width: 200px;
    border-radius: 20px;
    padding: 20px;
    border: 2px solid white;
    box-shadow: 1px 1px 5px black;
    color: white;
}

.drop:hover {
    cursor: pointer;
}