@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

.artist_focus_container{
    display: grid;
    grid-template-columns: 200px 10fr;
    text-align: left;
}

.artist_focus_label{
    font-family: Roboto;
}

.aside_menu{
    display: block;
}

@media only screen and (max-width: 600px){
    .artist_focus_container{
        grid-template-columns: 1fr;
    }

    .aside_menu{
        /* display: none; */
    }
}