@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');
body{
    background: #000000;
}

.App {
  text-align: center;
  background: #000000;
  color: white;
  height: 100vh;
  font-family: Roboto;
  font-weight: bold;
}

input{
    border-radius: 10px;
    padding: 12px;
    border: none;
    margin-right: 8px;
}

input:focus, textarea:focus, select:focus{
    outline: none;
}

button{
  cursor: pointer;
  border: none;
  background: purple;
  padding: 10px;
  border-radius: 50px;
  overflow: hidden;
  color: white;
}

button:hover{
  background: #9522D3;  
}

