@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

.hangout_container{
    display: grid;
    grid-template-columns: 200px auto;
    height: 100vh;
}

.hangout_container_main{
    text-align: left;
    padding-left: 20px;
    font-family: Roboto;
}

.hangout{
    height: 100%;
}

.meeting{
    height: 100%;
    width: 100%;
    min-height: 100%;
}


/* #jitsiMeeting-0{
    height: 100%;
    min-height: 500px;
} */

div[id*="jitsiMeeting"]{
    height: 100%;
    min-height: 100%;
    margin-right: 15px;
    border-radius: 10px !important;
}
#etherpad,#largeVideo,#largeVideoWrapper,#videospace,#videocontainer,#videoconference_page{
    border-radius: 10px !important;
}


@media only screen and (max-width: 600px){
    .hangout_container{
        grid-template-columns: 1fr;
    }
}
