@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

.home {
    display: grid;
    grid-template-columns: 200px 2fr 200px;
}



.left_menu button {
    border-radius: 0px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid black;
    margin-bottom: 2px;
    font-family: Roboto;
    font-weight: 600;
    font-size: large;
    color: white;
}

.left_menu button:hover{
    cursor: pointer;
    background: #000000;
    color: white;
    font-weight: 900;
}

.home_main{
    padding: 20px;
}

.home_main p {
    background: white;
    color: black;
    padding: 10px;
    border-radius: 3px;
}

@media only screen and (max-width: 600px){
    .home{
        display: flex;
        flex-direction: column;
    }
}