.song_preview{
    position: relative;
    top: 0px;
}

.battle_header{
    position: relative;
    text-align: left;
    background: white;
    color: white;
    background: rgba(0,0,0,0.5);
}

.battle_title{
    /* position: absolute; */
    /* margin-left: auto;
    margin-right: auto; */
    /* left: 0;
    right: 0; */
    text-align: center;
    font-size: x-large;
    padding: 0px;
    font-weight: bold;
    font-family: Arial;
    color: white;
    display: inline-block;
    position: relative;
    top: -30px;
    padding-left: 10px;
}

.battle_image{
    /* position: relative; */
    width: 60px;
    height: 60px;
    border-radius: 20px;
    border: 4px solid white;
    display: inline-block;
}

.user_songs_selector{
    padding-top: 40px;
    padding-bottom: 40px;
}

.join_battle_button{
    position: relative;
    top: 20px;
    background: white;
}

.spectate_button{
    background: white;
    position: relative;
    top: 40px;
}

.cancel_spectate_button{
    background: white;
    position: relative;
    top: 40px;
}

@media only screen and (max-width: 600px){
    .battle_title{
        font-size: small;
        margin: auto;
    }
}