.promo_is_closed {
    font-weight: bold;
    font-size: 50px;
}

.no_such_link {
    text-align : center;
    margin: auto;
    max-width: 400px;
    height: 100vh;
    display: flex; 
    align-items: center;
    justify-content: center;
    flex-direction: column;
}