.telling_you_bitches {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 500px;
    margin: auto;
}

.title {
    margin-bottom: 30px;
    font-size: 40px;
    font-weight: bold;
}