.profile_container{
    display: grid;
    grid-template-columns: 200px auto 200px;
    max-height: calc(100vh - 150px);
    overflow-y: scroll;
    margin-bottom: 200px;
    grid-template-areas: 'menu main rightmenu';
}

.profile_main{
    text-align: left;
    padding-left: 0px;
    padding-right: 0px;
    grid-area: main;
}

.profile_menu{
    padding: 20px;
    background: white;
    color: black;
    display: grid;
    grid-template-columns: auto auto;
    /* border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px; */
}



.right_menu{
    grid-area: rightmenu;
}

.profile_follow_menu{
    background: whitesmoke;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    text-align: right;
}

.profile_hangout > div{
    height: 800px;
    width: 100%;
}



.song_list{
    padding: 20px;
}

.song{
    display: grid;
    grid-template-columns : 100px auto;
    grid-template-rows: auto;
    grid-template-areas: 'cover player'
                         'info info';
}

.song_player{
    grid-area: player;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.song_cover{
    grid-area: cover;
    width: fill;
    height: 100px;
    /* border-top-right-radius: 10px;*/
    border-top-left-radius: 10px; 
    border-bottom-left-radius: 10px;
}

.songName{
    grid-area: sn;
}

.playCounter{
    grid-area: pc;
}

.visibilitySwitcher{
    grid-area: vs;
}

.songLikes{
    grid-area: sl;
    cursor: pointer;
}

.songComments{
    grid-area: sc;
}

.commentCounter{
    grid-area: cc;
}

.deleteSongButton{
    grid-area: ds;
    cursor: pointer;
    color: red;
}

.battleButton {
    grid-area: bb;
    cursor: pointer;
    font-weight: 900;
    position: relative;
    top: 0px;
}

.song_info{
    grid-area: info;
    /* display: flex; */
    /* flex-direction: column; */
    display:grid;
    grid-template-areas: "sn pc sl cc vs ds bb"
                        "sc sc sc sc sc sc sc";
    background : white;
    color: black;
    width : 96%;
    position: relative;
    left: 2%;
    margin-bottom: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    min-height: 90px;
}





.left_menu_container{
    position: relative;
    grid-area: menu;

}

.left_menu_fixed_container{
    position: fixed;
    top: 100 px;
    width : 200px;
}

.empty_profile_message{
    padding: 30px;
    text-align: center;
}

/* .profile_actions button{
    margin-right: 4px;
    display: grid;
    grid-template-columns: 20px 1fr;
} */

.profile_actions {
    display : grid;
    grid-template-columns : 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    align-content: center;
}

.profile_action{
    position: relative;
    display: grid;
    grid-template-columns: 20px 1fr;
    margin-right: 4px;
}

.profile_action span {
    position: relative;
    top: 2px;
    padding-right: 10px;
}

.profile_action_icon{
    /* position: absolute;
    top: 4px;
    left: 10px; */
    cursor:pointer;
}

.profile_action_icon:hover{
    color : red;
}

.profile_action_text {
    display: block;
}


@media only screen and (max-width : 600px) {
    .left_menu_container{
        display: block;
    }

    .left_menu_fixed_container {
        position: relative;
        width: 100%;
        /* top: 200px; */
    }

    .profile_container{
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        grid-template-areas: 'main'
                             'menu'
                             'rightmenu';
    }

    .empty_profile_message{
        padding: 4px;
    }

    .profile_action_text{
        display: none;
    }

    .song_info {
        grid-template-areas: "sn sn sn sn sn sn sn"
                             "pc sl cc vs ds ds ds" 
                             "bb bb bb bb bb bb bb"
                             "sc sc sc sc sc sc sc";
    }
}


@media only screen and (max-width: 990px){
    .profile_action{
        grid-template-columns: 1fr;
        text-align: center;
        margin: auto;
    }

    /* .profile_action_icon{
         position: relative;
         left: 20px;
    } */

    .profile_action{
        text-align: center;
    }

    .profile_action_text{
        display: none;
    }
}


.profile_username_setup{
    padding: 20px;
}

