.MuiDialog-container {
    width: 100vw !important;
}

/* .MuiDialogContent-root {
    width : 100vw;
} */
.MuiDialogContent-root{
    padding:0 !important;
}

.video{
    position: relative;
    top: 45px;
    z-index: 1;
}

ul.control-dots{
    position: relative;
    top: 10px;
    max-height: 30px;
}

.video_comments{
    background: white;
    border-radius: 10px;
    text-align: left;
    padding: 20px;
    display: grid;
    grid-template-rows: auto auto;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 200px !important;
}