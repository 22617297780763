.submitter_container{
    width: fit-content;
    height: fit-content;
    text-align: center;
}

.submitter_image {
    border-radius: 20px;
}

.submitter_username {
    color: black;
}