.player_and_drop {
    display : grid;
    grid-template-columns: 2fr 1fr;
    background: linear-gradient(to bottom, white, white);
    padding: 30px;
    color: black;
}

.music_player {
    text-align: left;
    display: flex;
}

.submitter_queue {
    display: flex;
    grid-gap: 20px;
    padding: 20px;
    background: linear-gradient(to bottom, #75599D, white);
}

.player {
    display: flex;
}

@media only screen and (max-width: 500px){
    .player_and_drop {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }

    .music_player{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }

    .player {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
}