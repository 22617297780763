
.form_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.signup_form{
    max-width: 500px;
    min-width: 350px;
    display: flex;
    flex-direction : column;
    /* align-items : center; */
    justify-content : center;
    gap: 4px;
    align-self: center;
}